<!-- 考试管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">学员分配</div>
      <div>
        <div>
          <!-- <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 67px"
            class="cinput"
            @click='dialogVisible=true'
            >+添加门店</el-button
          > -->
          <el-radio-group v-model="radio" style="height: 34px" class="cinput" @change='pagerole'>
            <el-radio-button label="0">未分配</el-radio-button>
            <el-radio-button label="1">已分配</el-radio-button>
            <!-- <el-radio-button label="学员"></el-radio-button>
            <el-radio-button label="院长"></el-radio-button>
            <el-radio-button label="审核员"></el-radio-button> -->
          </el-radio-group>
        </div>
        <div>
          <el-select
            v-model="levelId"
            placeholder="等级筛选"
            class="cinput ac"
            style="margin: 0 23px"
            @change='pagerole'
          >
            <el-option
              v-for="item in levelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-cascader
            @change="pagerole"
            placeholder="地区选择"
            :options="citys"
            class="cinput ac"
             clearable
            v-model='code'
          ></el-cascader>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="levelName" align="center" label="学员等级">
        </el-table-column>
        <el-table-column align="center" label="地区">
          <template slot-scope="scope">
            <div>{{scope.row.provinceName}}-{{scope.row.cityName}}-{{scope.row.areaName}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="storeName" align="center" label="门店">
        </el-table-column>
        <el-table-column prop="mentorName" align="center" label="导师" v-if='radio==1'>
        </el-table-column>
        <el-table-column prop="date" label="操作" align="center">
          <template slot-scope='scope'>
            <div>
              <el-link
                v-if='radio==0'
                type="primary"
                :underline="false"
                style="margin: 0 25px"
                @click="details(scope.row)"
                >分配导师</el-link>
                <el-link
                v-if='radio==1'
                type="primary"
                :underline="false"
                style="margin: 0 25px"
                @click="details(scope.row)"
                >修改导师</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 28px;
        "
      >
        <div class="line"></div>
        <div>学员信息</div>
      </div>
      <div class="title">
        <div>
          <div>姓名：</div>
          <div>{{studentobj.name}}</div>
        </div>
        <div>
          <div>地区：</div>
          <div>{{studentobj.provinceName}}{{studentobj.cityName}}{{studentobj.areaName}}</div>
        </div>
        <div>
          <div>门店：</div>
          <div>{{studentobj.storeName}}</div>
        </div>
      </div>
      <div class="title" style="margin-top: 22px; margin-bottom: 55px;justify-content: flex-start;">
        <div>
          <div>备考等级：</div>
          <div>{{studentobj.levelName}}</div>
        </div>
         <div style="margin-left:145px">
          <div>导师：</div>
          <div>{{studentobj.mentorName}}</div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 28px;
        "
      >
        <div class="line"></div>
        <div>导师选择</div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div style="width: 221px; display: flex">
          <el-input
            v-model="input"
            placeholder="输入导师名称"
            class="cinput ac"
            @keyup.enter.native="pagerole2"
          ></el-input>
          <div
            style="
              display: flex;
              height: 34px;
              width: 34px;
              align-items: center;
              background: #f7f7f7;
            "
          >
            <img
              v-if="isshow"
              src="../../images/search2.png"
              alt=""
              @click="pagerole2"
              style="background: #f7f7f7; width: 34px"
            />
            <img
              v-if="isshow == false"
              src="../../images/search1.png"
              alt=""
              style="background: #f7f7f7; width: 34px"
            />
          </div>
        </div>
         <el-cascader
            @change="pagerole2"
            placeholder="地区选择"
            :options="citys2"
            class="cinput ac"
        
            clearable
            v-model='code2'
          ></el-cascader>
      </div>
      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData2"
        style="width: 100%; max-height: 400px; margin-top: 11px"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="address" align="center" label="地区">
          <template slot-scope="scope">
            <div> <div>{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.areaName}}</div></div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-link type="primary" :underline="false" style="margin: 0 25px" @click="pointsmentor(scope.row.id)"
                >选择</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
       <div style="display: flex; align-items: center; justify-content: center;margin-top:20px">
        <el-pagination
          background
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="pageIndex2"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total2"
        >
        </el-pagination>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import {cityList} from '../../utils/cityCode'
import {roleList,pageroleList,levelList,studentList,addMentor} from '../../apis/index'
export default {
  data() {
    return {
      studentobj:{},
      total:0,
      total2:0,
      levelList:[],
      levelId:'',
      roleId:'',
      roleId2:'',
      pageIndex:1,
      pageSize:10,
      pageIndex2:1,
      pageSize2:10,
      code:'',
      value:'',
      citys:[],
      code:'',
      citys2:[],
      code2:'',
      radio1: "1",
      dialogVisible: false,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      isshow: false,
      isshow2: true,
      currentPage: 1,
      radio: 0,
      input: "",
      tableData: [
       
      ],
      tableData2:[]
    };
  },
  watch: {
    input(newdata, olddata) {
     
      if (newdata) {
        this.isshow = true;
      } else {
       
        this.input=''
        this.pagerole2()
      
        this.isshow = false;
      }
    },
    radio(newdata, olddata) {
      if (newdata == "已发布") {
        this.isshow2 = true;
      } else {
        this.isshow2 = false;
      }
    },
  },
  created(){
   let codes=cityList()//获取省市区数据
   this.citys=this.citys2=codes
   
   this.role().then(res=>{
     this.level()
   })
  },
  methods: {
   async pointsmentor(id){//分配导师
      const params={
        levelId:this.studentobj.levelId,
        mentorId:id,
        traineeId:this.studentobj.id,
      }
     const {data}=await addMentor(params)
     if(data.code==200){
       this.$message.success('操作成功')
       this.dialogVisible=false
        this.pagerole()
     }else{
       this.$message.error(data.msg)
     }
    },
 async level(){//获取等级列表
     const {data}=await levelList({roleId:this.roleId})
     if(data.code==200){
       
        const obj={
           id:'',
           name:'全部'
         }
       this.levelList=[obj,...data.data]
     }else{
       this.$message.error(data.msg)
     }
    },
   async role(){//查询角色数据
     const {data}=await roleList()
     let list=data.data
     let roleId=list.find(item=>{
       return item.name=='学员'
     })
      let roleId2=list.find(item=>{
       return item.name=='导师'
     })
     this.roleId=roleId.id//学员id
     this.roleId2=roleId2.id//导师id
     this.pagerole()
    },
    async pagerole(){//查询相应学员列表
      const params={
        roleId:this.roleId,
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        levelId:this.levelId,
        province:this.code[0],
        city:this.code[1],
        area:this.code[2],
        isAssignMentor:Number(this.radio),
        isTrainee:1,
      }
      const {data}=await studentList(params)
      this.tableData=data.data.records
      this.total=Number(data.data.total)
    },
    async pagerole2(){//查询相应导师列表
      const params={
        roleId:this.roleId2,
        pageIndex:this.pageIndex2,
        pageSize:this.pageSize2,
        province:this.code2[0],
        city:this.code2[1],
        area:this.code2[2],
        searchStr:this.input
      }
      const {data}=await pageroleList(params)
      this.tableData2=data.data.records
      this.total2=Number(data.data.total)
    },
   
    details(row) {
      this.studentobj=row
      // this.code2=[row.province.toString(),row.city.toString(),row.area.toString()]
      this.pagerole2()
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    search() {
      console.log("我是搜索");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.pagerole()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex=val
      this.pagerole()
    },
     handleSizeChange2(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize2=val
      this.pagerole2()
    },
    handleCurrentChange2(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex2=val
      this.pagerole2()
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 14px;
}
.title {
  justify-content: space-between;
  display: flex;
  color: #707070;
  font-size: 20px;
  margin: 0 20px;
}
.title > div {
  display: flex;
}
/deep/.el-form-item__label {
  font-size: 20px;
  font-weight: bold;
}
.fdiv {
  font-size: 20px;
}
/deep/.dialog-footer {
  text-align: center;
}
.inp /deep/.el-input__inner {
  border: none;
  font-size: 20px;
}
.inp2 /deep/.el-input__inner,
/deep/.el-radio__label {
  // border: none;
  font-size: 20px;
}
.line {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin-right: 11px;
}
/deep/.el-dialog__body {
  //   padding: 30px 36px;
}
/deep/.el-radio-group span {
  width: 106px;
}
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
